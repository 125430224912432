<template>
  <v-app>
      <!-- <v-layout wrap justify-center>
      <v-flex xs12>
        <v-img
          src="./../../../../assets/images/Parambikulam Assests-07.jpg"
          width="100%"
          contain
        />
      </v-flex>
    </v-layout> -->
    <!--   <v-img src="./../../../../assets/images/success.png"> -->
    
      <v-layout wrap justify-center>
        <v-flex text-center>
          <v-card flat text-center class="pt-8">
            <span class="itemCaption" style="color:black;text-align:center">Payment Successful
            </span>
            <br>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
            <!-- <v-layout justify-center>
              <v-flex md1 offset-md1 >
        <v-img class="text-center" src="./../../../../assets/images/success.png" height="30px" width="30px" style="text-align:center"></v-img>

              </v-flex>

            </v-layout> -->

              <h6
            style="font-size:14px!important;text-align:center!important;color:black;"
          >Thank You for the Purchasing</h6>
          <!-- <p style="text-align:center"><a :href="'https://api.parambikulam.org/booking/ticketpdf/?id='+paymentId" target="_blank"> Please click here to download ticket </a></p> -->
          </v-card>
        </v-flex>
      </v-layout>
    <!-- </v-img> -->
  </v-app>
</template>
<script>
import axios from "axios";
export default {
  props:["paymentId"],
  name: "Success",
  data() {
    return {
      appLoading: false,
    };
  },
  
  methods: {
    retpg() {
      this.$router.push({ path: "/" });
    },
    home: function () {
      this.homeload = true;
      setTimeout(
        function () {
          this.homeload = false;
          // alert('cool');
        }.bind(this),
        4000
      );
    },
    list: function () {
      axios({
        method: "GET",
        url: "/donation/sendmail?id=" + this.id,
      })
        // .then((response) => {})
        // .catch((e) => {});
    },
  },
};
</script>
<style>
.loadingstyle {
  text-align: center;
  margin: 0px;
  padding: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 30001;
  opacity: 0.8;
}
.btn.buttonstyle1 {
  border-radius: 25px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 1px !important;
  padding-bottom: 4px !important;
}
@media only screen and (min-width: 1006px) {
  .ht {
    height: auto !important;
  }
  .centered {
    position: absolute;
    top: 40%;
    left: 48%;
  }
}

.centered1 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .ht {
    height: 1000px !important;
  }
  .centered {
    position: absolute;
    top: 40%;
    left: 48%;
  }
  /* .btn.buttonstyle1 {
    border-radius: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  } */
}
</style>